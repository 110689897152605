import React from 'react';
import '../styles/Cart.css';

const Cart = ({ cart, setCart, onProceedToCheckout, orderDetails }) => {
  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const calculateTotal = () => {
    const itemsTotal = cart.reduce((sum, item) => {
      const itemTotal = typeof item.totalPrice === 'number' ? item.totalPrice : 
                        (Number(item.price) || 0) * (item.quantity || 1);
      return sum + itemTotal;
    }, 0);
    const deliveryPrice = orderDetails?.deliveryPrice || 0;
    return (itemsTotal + deliveryPrice).toFixed(2);
  };

  return (
    <div className="cart">
      <h2>עגלת קניות</h2>
      {cart.map((item, index) => {
        const itemTotal = typeof item.totalPrice === 'number' ? item.totalPrice : 
                          (Number(item.price) || 0) * (item.quantity || 1);
        return (
          <div key={item.id || index} className="cart-item">
            <span>{item.name} x {item.quantity || 1}</span>
            <span>₪{itemTotal.toFixed(2)}</span>
            {item.extras && item.extras.length > 0 && (
              <div className="cart-item-extras">
                <strong>תוספות:</strong>
                {item.extras.map((extra, idx) => (
                  <div key={idx}>{extra}</div>
                ))}
              </div>
            )}
            <button onClick={() => removeFromCart(index)}>הסר</button>
          </div>
        );
      })}
      {orderDetails?.type === 'delivery' && (
        <div className="delivery-info">
          <span>דמי משלוח:</span>
          <span>₪{orderDetails.deliveryPrice.toFixed(2)}</span>
        </div>
      )}
      <div className="cart-total">
        <strong>סה"כ: ₪{calculateTotal()}</strong>
      </div>
      <button onClick={onProceedToCheckout} className="checkout-button">המשך לתשלום</button>
    </div>
  );
};

export default Cart;