import React, { useState } from 'react';
import takeawayIcon from '../assets/icons/takeaway-icon.png';
import deliveryIcon from '../assets/icons/delivery-icon.png';
import '../styles/OrderTypeSelection.css';

const deliveryData = {
  'אשקלון': {
    'אשקלון': 20,
    'עיר היין': 25,
    'עיר ימים': 25,
    'בית עלמין': 25,
    'אזורי תעשייה': 30,
    'קוסמוס': 30,
    'כפר סילבר': 35,
    'גלובוס': 35,
    'קצא': 40,
    'מבקיעים': 40,
    // ... (כל שאר הערים והמחירים)
  },
  'קריית גת': {
    'קריית גת': 15,
    'אינטל': 25,
    'עוזה': 30,
    'פלוגות': 30,
    'שדה משה': 50,
    'קיבוץ גת': 60,
    'זבדיאל': 60,
    'שלווה/אבן שמואל/נועם/איתן/רווחה': 50,
    'סגולה': 70,
    'יד נתן': 60,
    'לכיש': 70,
    'תרקומיא': 120
  }
};

const OrderTypeSelection = ({ onOrderTypeSelected }) => {
  const [orderType, setOrderType] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [address, setAddress] = useState({ street: '', houseNumber: '' });

  const branches = Object.keys(deliveryData);

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
    setSelectedBranch('');
    setSelectedCity('');
    setAddress({ street: '', houseNumber: '' });
  };

  const handleBranchChange = (branch) => {
    setSelectedBranch(branch);
    setSelectedCity('');
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (orderType === 'pickup' && selectedBranch) {
      onOrderTypeSelected({ type: orderType, branch: selectedBranch });
    } else if (orderType === 'delivery' && selectedBranch && selectedCity && address.street && address.houseNumber) {
      const deliveryPrice = deliveryData[selectedBranch][selectedCity];
      onOrderTypeSelected({ 
        type: orderType, 
        branch: selectedBranch,
        address: { city: selectedCity, ...address },
        deliveryPrice
      });
    } else {
      alert('נא למלא את כל הפרטים הנדרשים');
    }
  };

  return (
    <div className="order-type-container">
      <h2>בחר סוג הזמנה</h2>
      <div className="order-options">
        <button 
          className={`option-card ${orderType === 'pickup' ? 'selected' : ''}`} 
          onClick={() => handleOrderTypeChange('pickup')}
        >
          <img src={takeawayIcon} alt="איסוף עצמי" />
          <p>איסוף עצמי</p>
        </button>
        <button 
          className={`option-card ${orderType === 'delivery' ? 'selected' : ''}`} 
          onClick={() => handleOrderTypeChange('delivery')}
        >
          <img src={deliveryIcon} alt="משלוח" />
          <p>משלוח</p>
        </button>
      </div>

      {orderType === 'pickup' && (
        <div className="additional-info">
          <h3>בחר סניף לאיסוף</h3>
          <div className="branch-buttons">
            {branches.map((b) => (
              <button
                key={b}
                className={`branch-button ${selectedBranch === b ? 'selected' : ''}`}
                onClick={() => setSelectedBranch(b)}
              >
                {b}
              </button>
            ))}
          </div>
        </div>
      )}

      {orderType === 'delivery' && (
        <div className="additional-info">
          <h3>בחר סניף למשלוח</h3>
          <div className="branch-buttons">
            {branches.map((b) => (
              <button
                key={b}
                className={`branch-button ${selectedBranch === b ? 'selected' : ''}`}
                onClick={() => handleBranchChange(b)}
              >
                {b}
              </button>
            ))}
          </div>

          {selectedBranch && (
            <>
              <h3>בחר עיר למשלוח</h3>
              <select 
                value={selectedCity} 
                onChange={(e) => handleCityChange(e.target.value)}
                className="city-select"
              >
                <option value="">בחר עיר</option>
                {Object.keys(deliveryData[selectedBranch]).map(city => (
                  <option key={city} value={city}>
                    {city} - ₪{deliveryData[selectedBranch][city]}
                  </option>
                ))}
              </select>
            </>
          )}

          {selectedCity && (
            <>
              <h3>הכנס כתובת למשלוח</h3>
              <input
                type="text"
                name="street"
                value={address.street}
                onChange={handleAddressChange}
                placeholder="רחוב"
              />
              <input
                type="text"
                name="houseNumber"
                value={address.houseNumber}
                onChange={handleAddressChange}
                placeholder="מספר בית"
              />
            </>
          )}
        </div>
      )}

      {((orderType === 'pickup' && selectedBranch) || 
        (orderType === 'delivery' && selectedBranch && selectedCity && address.street && address.houseNumber)) && (
        <button className="submit-button" onClick={handleSubmit}>המשך להזמנה</button>
      )}
    </div>
  );
};

export default OrderTypeSelection;