import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/CheckoutForm.css';

const CheckoutForm = ({ cart, orderDetails }) => {
  const [shippingDetails, setShippingDetails] = useState({
    first_name: '',
    last_name: '',
    address_1: '',
    city: '',
    postcode: '',
    phone: '',
    email: ''
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchPaymentMethods();
    calculateTotalOrderPrice();
    if (orderDetails?.type === 'delivery') {
      setShippingDetails(prevDetails => ({
        ...prevDetails,
        address_1: `${orderDetails.address.street} ${orderDetails.address.houseNumber}`,
        city: orderDetails.address.city
      }));
    }
  }, [cart, orderDetails]);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get('https://piazza.cs1118.cloud-node.net/wp-json/wc/v3/payment_gateways', {
        auth: {
          username: 'ck_7173bc2932b4e313a31b19cd41b2ec308705a27b',
          password: 'cs_c4e340f2e17a4d2ed2cb415aecaa713c6aef9632'
        }
      });
      const enabledMethods = response.data.filter(method => method.enabled);
      setPaymentMethods(enabledMethods);
      if (enabledMethods.length > 0) {
        setSelectedPaymentMethod(enabledMethods[0].id);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      setError('שגיאה בטעינת אמצעי התשלום. אנא נסה שוב מאוחר יותר.');
    }
  };

  const calculateTotalOrderPrice = () => {
    const itemsTotal = cart.reduce((sum, item) => {
      const itemPrice = Number(item.price || 0) * item.quantity;
      const extrasPrice = item.selectedExtras.reduce((extraSum, extra) => extraSum + Number(extra.price || 0), 0) * item.quantity;
      return sum + itemPrice + extrasPrice;
    }, 0);
    const deliveryPrice = orderDetails?.deliveryPrice || 0;
    setTotalOrderPrice((itemsTotal + deliveryPrice).toFixed(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  const validateForm = () => {
    const requiredFields = ['first_name', 'last_name', 'address_1', 'city', 'phone', 'email'];
    for (let field of requiredFields) {
      if (!shippingDetails[field]) {
        setError(`אנא מלא את השדה: ${field}`);
        return false;
      }
    }
    if (!/^\S+@\S+\.\S+$/.test(shippingDetails.email)) {
      setError('אנא הזן כתובת אימייל תקינה');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setError('');
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const lineItems = cart.map(item => {
        const itemPrice = Number(item.price || 0);
        const extrasPrice = item.selectedExtras.reduce((sum, extra) => sum + Number(extra.price || 0), 0);
        const totalPriceForItem = (itemPrice + extrasPrice) * item.quantity;

        const extrasByCategory = item.selectedExtras.reduce((acc, extra) => {
          if (!acc[extra.category]) {
            acc[extra.category] = [];
          }
          acc[extra.category].push(`${extra.name} (₪${extra.price})`);
          return acc;
        }, {});

        const metaData = [
          {
            key: 'שם_מוצר',
            value: item.name
          },
          {
            key: 'מחיר_בסיס',
            value: itemPrice.toFixed(2)
          },
          ...Object.entries(extrasByCategory).map(([category, extras]) => ({
            key: category,
            value: extras.join(', ')
          })),
          {
            key: 'סהכ_תוספות',
            value: extrasPrice.toFixed(2)
          }
        ];

        return {
          product_id: item.id,
          quantity: item.quantity,
          subtotal: totalPriceForItem.toFixed(2),
          total: totalPriceForItem.toFixed(2),
          meta_data: metaData
        };
      });

      const orderData = {
        payment_method: selectedPaymentMethod,
        payment_method_title: paymentMethods.find(m => m.id === selectedPaymentMethod)?.title || '',
        set_paid: true,
        status: 'processing',
        billing: { ...shippingDetails },
        shipping: { ...shippingDetails },
        line_items: lineItems,
        shipping_lines: [
          {
            method_title: orderDetails.type === 'delivery' ? 'משלוח' : 'איסוף עצמי',
            method_id: orderDetails.type === 'delivery' ? 'delivery' : 'pickup',
            total: orderDetails.deliveryPrice?.toString() || '0'
          }
        ],
        meta_data: [
          {
            key: 'total_price',
            value: totalOrderPrice
          },
          {
            key: 'סניף',
            value: orderDetails.branch
          },
          {
            key: 'סוג_הזמנה',
            value: orderDetails.type === 'delivery' ? 'משלוח' : 'איסוף עצמי'
          }
        ]
      };

      const response = await axios.post('https://pizza-bariboa.co.il/wp-json/wc/v3/orders', orderData, {
        auth: {
          username: 'ck_7173bc2932b4e313a31b19cd41b2ec308705a27b',
          password: 'cs_c4e340f2e17a4d2ed2cb415aecaa713c6aef9632'
        }
      });

      if (response.data.id) {
        alert('ההזמנה התקבלה ושולמה בהצלחה!');
        window.location.href = '/thank-you';
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setError('אירעה שגיאה בעת יצירת ההזמנה. אנא נסה שנית.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>טוען...</div>;

  return (
    <div className="checkout-container">
      <h2>פרטי משלוח</h2>
      {error && <div className="error-message">{error}</div>}
      <input 
        type="text" 
        name="first_name" 
        placeholder="שם פרטי" 
        value={shippingDetails.first_name} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      <input 
        type="text" 
        name="last_name" 
        placeholder="שם משפחה" 
        value={shippingDetails.last_name} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      <input 
        type="text" 
        name="address_1" 
        placeholder="כתובת" 
        value={shippingDetails.address_1} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      <input 
        type="text" 
        name="city" 
        placeholder="עיר" 
        value={shippingDetails.city} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      <input 
        type="text" 
        name="postcode" 
        placeholder="מיקוד" 
        value={shippingDetails.postcode} 
        onChange={handleChange} 
        className="input-field"
      />
      <input 
        type="tel" 
        name="phone" 
        placeholder="טלפון" 
        value={shippingDetails.phone} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      <input 
        type="email" 
        name="email" 
        placeholder="אימייל" 
        value={shippingDetails.email} 
        onChange={handleChange} 
        className="input-field"
        required
      />
      
      <h2>שיטת תשלום</h2>
      <select 
        value={selectedPaymentMethod} 
        onChange={(e) => setSelectedPaymentMethod(e.target.value)} 
        className="input-field"
      >
        {paymentMethods.map(method => (
          <option key={method.id} value={method.id}>{method.title}</option>
        ))}
      </select>

      <h3>סה"כ לתשלום: ₪{totalOrderPrice}</h3>
      
      <button onClick={handleSubmit} className="checkout-button" disabled={isLoading}>
        {isLoading ? 'מעבד...' : 'בצע תשלום והשלם הזמנה'}
      </button>
    </div>
  );
};

export default CheckoutForm;