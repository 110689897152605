import React, { useState, useEffect } from 'react';
import '../styles/ProductSelection.css';

const ProductSelection = ({ products, addToCart, selectedCategory }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const handleProductClick = async (product) => {
    try {
      const response = await fetch(`/wp-json/wc/v3/products/${product.id}/toppings`);
      
      if (!response.ok) {
        throw new Error(`Failed to load toppings: ${response.status}`);
      }

      const data = await response.json();
      console.log("Fetched toppings:", data);

      setSelectedProduct({ ...product, ...data });
      initializeSelectedExtras(data.toppings);
      setTotalPrice(Number(product.price) || 0);
      setQuantity(1);
    } catch (error) {
      console.error('Error fetching toppings:', error);
    }
  };

  const initializeSelectedExtras = (toppings) => {
    const initialExtras = {};
    toppings.forEach(category => {
      if (category.selection_type === 'single' && category.is_mandatory) {
        initialExtras[category.name] = category.toppings[0].name;
      } else {
        initialExtras[category.name] = [];
      }
    });
    setSelectedExtras(initialExtras);
  };

  const handleExtraToggle = (categoryName, toppingName, price) => {
    const category = selectedProduct.toppings.find(cat => cat.name === categoryName);
    
    setSelectedExtras(prevExtras => {
      const newExtras = { ...prevExtras };
      
      if (category.selection_type === 'single') {
        newExtras[categoryName] = toppingName;
      } else {
        if (!Array.isArray(newExtras[categoryName])) {
          newExtras[categoryName] = [];
        }
        
        const index = newExtras[categoryName].indexOf(toppingName);
        if (index > -1) {
          newExtras[categoryName].splice(index, 1);
        } else {
          if (category.selection_type === 'limited' && 
              newExtras[categoryName].length >= category.max_selections) {
            return prevExtras; // Don't add if limit reached
          }
          newExtras[categoryName].push(toppingName);
        }
      }
      
      return newExtras;
    });

    updateTotalPrice();
  };

  const updateTotalPrice = () => {
    if (selectedProduct) {
      const basePrice = Number(selectedProduct.price) || 0;
      const extrasPrice = selectedProduct.toppings.reduce((sum, category) => {
        const selectedToppings = selectedExtras[category.name];
        if (Array.isArray(selectedToppings)) {
          return sum + selectedToppings.reduce((toppingSum, toppingName) => {
            const topping = category.toppings.find(t => t.name === toppingName);
            return toppingSum + (topping ? Number(topping.price) : 0);
          }, 0);
        } else if (selectedToppings) {
          const topping = category.toppings.find(t => t.name === selectedToppings);
          return sum + (topping ? Number(topping.price) : 0);
        }
        return sum;
      }, 0);
      setTotalPrice((basePrice + extrasPrice) * quantity);
    }
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  useEffect(() => {
    updateTotalPrice();
  }, [selectedProduct, selectedExtras, quantity]);

  const handleAddToCart = () => {
    if (selectedProduct) {
      const missingMandatory = selectedProduct.toppings.filter(category => 
        category.is_mandatory && 
        (!selectedExtras[category.name] || 
         (Array.isArray(selectedExtras[category.name]) && selectedExtras[category.name].length === 0))
      );

      if (missingMandatory.length > 0) {
        alert(`אנא בחר אפשרות מהקטגוריות הבאות: ${missingMandatory.map(c => c.name).join(', ')}`);
        return;
      }

      const selectedExtrasDetails = selectedProduct.toppings.flatMap(category => {
        const selected = selectedExtras[category.name];
        if (Array.isArray(selected)) {
          return selected.map(toppingName => ({
            category: category.name,
            name: toppingName,
            price: category.toppings.find(t => t.name === toppingName)?.price || 0
          }));
        } else if (selected) {
          return [{
            category: category.name,
            name: selected,
            price: category.toppings.find(t => t.name === selected)?.price || 0
          }];
        }
        return [];
      });

      addToCart({
        ...selectedProduct,
        selectedExtras: selectedExtrasDetails,
        totalPrice: totalPrice.toFixed(2), // ודא שהמחיר מוצג עם 2 ספרות עשרוניות
        quantity
      });

      setSelectedProduct(null);
      setSelectedExtras({});
      setTotalPrice(0);
      setQuantity(1);
    }
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setSelectedExtras({});
    setTotalPrice(0);
    setQuantity(1);
  };

  const groupedProducts = products.reduce((acc, product) => {
    product.categories.forEach((category) => {
      if (!acc[category.name]) {
        acc[category.name] = [];
      }
      acc[category.name].push(product);
    });
    return acc;
  }, {});

  return (
    <div className="product-selection-container">
      {Object.keys(groupedProducts).map((categoryName) => (
        <div key={categoryName} className="category-section">
          <h2 className="category-title">{categoryName}</h2>
          <div className="product-grid">
            {groupedProducts[categoryName].map((product) => (
              <div key={product.id} className="product-card" onClick={() => handleProductClick(product)}>
                <div className="product-image-container">
                  <img src={product.images[0]?.src || 'placeholder-image.jpg'} alt={product.name} />
                </div>
                <h3>{product.name}</h3>
                <p className="description">{product.short_description}</p>
                <p className="price">₪{product.price}</p>
              </div>
            ))}
          </div>
        </div>
      ))}

      {selectedProduct && (
        <div className="product-modal-overlay">
          <div className="product-modal">
            <button className="modal-close" onClick={closeModal}>×</button>
            <div className="product-image-popup">
              <img src={selectedProduct.images[0]?.src || 'placeholder-image.jpg'} alt={selectedProduct.name} />
            </div>
            <div className="modal-content">
              <h3 className="product-title">{selectedProduct.name}</h3>
              <p className="product-description">{selectedProduct.description}</p>
              <p className="product-price">₪{selectedProduct.price}</p>
              
              {selectedProduct.toppings && selectedProduct.toppings.length > 0 ? (
                selectedProduct.toppings.map((category, catIndex) => (
                  <div key={catIndex} className="toppings-section">
                    <h4 className="toppings-title">
                      {category.name} 
                      {category.is_mandatory && <span className="mandatory-label"> (חובה)</span>}
                      {category.selection_type === 'limited' && 
                        <span className="selection-limit"> (עד {category.max_selections})</span>
                      }
                    </h4>
                    <div className="toppings-options">
                      {category.toppings.map((topping, toppingIndex) => (
                        <button
                          key={toppingIndex}
                          className={`topping-option ${
                            (Array.isArray(selectedExtras[category.name]) 
                              ? selectedExtras[category.name].includes(topping.name)
                              : selectedExtras[category.name] === topping.name) 
                            ? 'selected' : ''
                          }`}
                          onClick={() => handleExtraToggle(category.name, topping.name, topping.price)}
                        >
                          {topping.name} (₪{topping.price})
                        </button>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <p>אין תוספות זמינות עבור מוצר זה</p>
              )}

              <div className="quantity-control">
                <button className="quantity-button" onClick={() => handleQuantityChange(-1)}>-</button>
                <span className="quantity-display">{quantity}</span>
                <button className="quantity-button" onClick={() => handleQuantityChange(1)}>+</button>
              </div>

              <p>סה"כ מחיר: ₪{(totalPrice || 0).toFixed(2)}</p>

              <button className="add-to-cart-button" onClick={handleAddToCart}>
                הוסף ₪{(totalPrice || 0).toFixed(2)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSelection;