import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OrderTypeSelection from './components/OrderTypeSelection';
import ProductSelection from './components/ProductSelection';
import Sidebar from './components/Sidebar';
import Cart from './components/Cart';
import CheckoutForm from './components/CheckoutForm';
import './styles/App.css';

function App() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [currentStep, setCurrentStep] = useState('orderType');
  const [cart, setCart] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        let allProducts = [];
        let currentPage = 1;
        let hasMore = true;

        while (hasMore) {
          const productsResponse = await axios.get('https://pizza-bariboa.co.il/wp-json/wc/v3/products', {
            auth: {
              username: 'ck_7173bc2932b4e313a31b19cd41b2ec308705a27b',
              password: 'cs_c4e340f2e17a4d2ed2cb415aecaa713c6aef9632'
            },
            params: {
              per_page: 100,
              page: currentPage,
            }
          });

          allProducts = [...allProducts, ...productsResponse.data];

          if (productsResponse.data.length < 100) {
            hasMore = false;
          } else {
            currentPage++;
          }
        }

        setProducts(allProducts);
        setLoading(false);

        const categoriesResponse = await axios.get('https://pizza-bariboa.co.il/wp-json/wc/v3/products/categories', {
          auth: {
            username: 'ck_7173bc2932b4e313a31b19cd41b2ec308705a27b',
            password: 'cs_c4e340f2e17a4d2ed2cb415aecaa713c6aef9632'
          }
        });
        setCategories(categoriesResponse.data);
      } catch (error) {
        setLoading(false);
        setError('שגיאה בטעינת מוצרים');
        console.error('Error fetching data:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleOrderTypeSelected = (details) => {
    setOrderDetails(details);
    setCurrentStep('productSelection');
  };

  const addToCart = (item) => {
    setCart(prevCart => [...prevCart, {
      ...item,
      id: Date.now(),
      extras: item.selectedExtras ? item.selectedExtras.map(extra => `${extra.category}: ${extra.name} (₪${extra.price})`) : []
    }]);
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleProceedToCheckout = () => {
    setCurrentStep('checkout');
  };

  return (
    <div className="App">
      {currentStep === 'orderType' && (
        <OrderTypeSelection onOrderTypeSelected={handleOrderTypeSelected} />
      )}

      {currentStep === 'productSelection' && (
        <div className="main-content">
          <Sidebar categories={categories} onCategorySelect={handleCategorySelect} />
          <div className="product-area">
            {loading ? (
              <p>טוען מוצרים...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <ProductSelection 
                products={products} 
                addToCart={addToCart} 
                selectedCategory={selectedCategory}
              />
            )}
          </div>
          <Cart cart={cart} setCart={setCart} onProceedToCheckout={handleProceedToCheckout} orderDetails={orderDetails} />
        </div>
      )}

      {currentStep === 'checkout' && (
        <CheckoutForm cart={cart} orderDetails={orderDetails} />
      )}
    </div>
  );
}

export default App;